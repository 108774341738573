"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.companyBankAccountValidations = exports.companyAddressValidations = exports.companySignatoryValidations = exports.companyAttributesValidation = void 0;
const validates_1 = require("core/helpers/validates");
const companyAttributesValidation = (values, translate) => {
    const formErrors = {
        identificationNumber: !values.foreignCompany ? (0, validates_1.required)(values.identificationNumber, translate) : undefined,
        name: (0, validates_1.required)(values.name, translate),
        legalStatus: (0, validates_1.required)(values.legalStatus, translate)
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.companyAttributesValidation = companyAttributesValidation;
const companySignatoryValidations = (values, translate) => {
    const formErrors = {
        firstname: (0, validates_1.required)(values.firstname, translate),
        lastname: (0, validates_1.required)(values.lastname, translate)
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.companySignatoryValidations = companySignatoryValidations;
const companyAddressValidations = (values, translate) => {
    const formErrors = {
        address: (0, validates_1.required)(values.address, translate),
        type: (0, validates_1.required)(values.type, translate)
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.companyAddressValidations = companyAddressValidations;
const companyBankAccountValidations = (values, translate) => {
    const formErrors = {
        name: (0, validates_1.required)(values.name, translate),
        code: (0, validates_1.required)(values.code, translate),
        accountNumber: (0, validates_1.required)(values.accountNumber, translate)
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.companyBankAccountValidations = companyBankAccountValidations;
