"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateFileWithExtension = exports.checkSymbolsAndNumbersLA = exports.checkSymbolsAndNumbersGE = exports.sentenceContainsTwoWordsAndLetter = exports.requiredDateRange = exports.requiredUpload = exports.requiredCorporateEmail = exports.requiredPositiveNumber = exports.requiredNotEmptyArray = exports.required = exports.requiredPersonalNumber = void 0;
const formik_1 = require("formik");
const requiredPersonalNumber = (value, translate) => value === null || !/^\d{11}$/.test(value) ? translate('required_Number', 'სავალდებულოა 11 ნიშნა რიცხვი') : undefined;
exports.requiredPersonalNumber = requiredPersonalNumber;
const required = (value, translate) => value === undefined || value === null || value === '' ? translate('required_field', 'სავალდებულო ველი') : undefined;
exports.required = required;
const requiredNotEmptyArray = (value, translate) => !value || (0, formik_1.isEmptyArray)(value) ? translate('required_field', 'სავალდებულო ველი') : undefined;
exports.requiredNotEmptyArray = requiredNotEmptyArray;
const requiredPositiveNumber = (value, translate) => value && value > 0 ? undefined : translate('required_positive_number', 'სავალდებულოა დადებითი რიცხვი');
exports.requiredPositiveNumber = requiredPositiveNumber;
const requiredCorporateEmail = (value, translate) => value === null || !/^[A-Za-z0-9._%+-]+@iliauni\.edu\.ge$/i.test(value) ? translate('required_email', 'გთხოვთ შეიყვანოთ ილიაუნის ელ.ფოსტა') : undefined;
exports.requiredCorporateEmail = requiredCorporateEmail;
const requiredUpload = (value, translate) => value === undefined || value === null || value === '' ? translate('required_upload_file', 'სავალდებულო ფაილის ატვირთვა') : undefined;
exports.requiredUpload = requiredUpload;
const requiredDateRange = (value, translate) => !value || !/—/.test(value) ? translate('required_date_range', 'გთხოვთ, აირჩიოთ თარიღის დიაპაზონი') : undefined;
exports.requiredDateRange = requiredDateRange;
const sentenceContainsTwoWordsAndLetter = (sentence, translate) => {
    // checks if sentence has at least two words and one letter
    if (sentence !== null) {
        const words = sentence.split(' ');
        const letterPattern = /[a-zA-Z\u10D0-\u10FF]/;
        if (words.length >= 2) {
            if (!letterPattern.test(sentence)) {
                return translate('required_includes_letters', 'გთხოვთ დარწმუნდეთ, რომ წინადადება შეიცავს ასოებსაც');
            }
            else {
                return undefined;
            }
        }
        else {
            return translate('required_target_contains', 'გთხოვთ დარწმუნდეთ, რომ მიზნობრიობა შეიცავს არანაკლებ ორ სიტყვას');
        }
    }
    else {
        return (0, exports.required)(sentence, translate);
    }
};
exports.sentenceContainsTwoWordsAndLetter = sentenceContainsTwoWordsAndLetter;
// export const checkSymbolsAndNumbers = (word: string | null): string | undefined => {
//   if (word && !/^[a-zA-Z\-\u10A0-\u10FF]+$/.test(word)) {
//     return 'გთხოვთ დარწმუნდეთ, რომ სიტყვა არ შეიცავს სიმბოლოებს და რიცხვებს'
//   } else {
//     return undefined
//   }
// }
const checkSymbolsAndNumbersGE = (word, translate) => {
    const regex = /^[-\u10A0-\u10FF]+$/;
    if (word && (!regex.test(word) || word.startsWith('-') || word.endsWith('-'))) {
        return translate('required_georgian_letters', 'გთხოვთ დარწმუნდეთ, რომ სიტყვა შეიცავს მხოლოდ ქართულ ასოებს');
    }
    else {
        return undefined;
    }
};
exports.checkSymbolsAndNumbersGE = checkSymbolsAndNumbersGE;
const checkSymbolsAndNumbersLA = (word, translate) => {
    const regex = /^[a-zA-Z-]+$/;
    if (word && (!regex.test(word) || word.startsWith('-') || word.endsWith('-'))) {
        return translate('required_latin_letters', 'გთხოვთ დარწმუნდეთ, რომ სიტყვა შეიცავს მხოლოდ ლათინურ ასოებს');
    }
    else {
        return undefined;
    }
};
exports.checkSymbolsAndNumbersLA = checkSymbolsAndNumbersLA;
const validateFileWithExtension = (fileName, allowedExtensions) => {
    const splitFileName = fileName.split('.');
    const extension = splitFileName[splitFileName.length - 1];
    if (extension) {
        const lowerCasedExtension = extension.toLowerCase();
        return allowedExtensions.some(el => el === lowerCasedExtension);
    }
    return false;
};
exports.validateFileWithExtension = validateFileWithExtension;
