"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.approverStatuses = exports.templateTypes = exports.procurementTypes = exports.parseProcurementSubStatus = exports.parseProcurementVisualStatus = exports.parseProcurementData = exports.parseProcurementTemplate = exports.parseProcurementItems = exports.parseExportProcurementItems = exports.parseProcurementsForSelect = exports.parseSuggestedApprovals = exports.parseApprovedItemSubmissionsForSelect = exports.parseApprovedItemCpvCodesForSelect = exports.parseVerifiedItems = exports.parseProcurementApprover = exports.parseProcurementApprovers = exports.parseProcurementFiles = exports.parseProcurementCalculateItemsData = exports.parseProcurementItemsData = exports.parseProcurementItem = exports.parseUpdateProcurement = exports.parseProcurement = exports.parseProcurements = void 0;
const types_1 = require("api/documents/purchases/procurements/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const parsers_1 = require("api/documents/purchases/procurementCorrections/parsers");
const helper_1 = require("api/helper");
const parsers_2 = require("api/parsers/parsers");
const parsers_3 = require("api/files/parsers");
const parsers_4 = require("api/documents/purchases/invoices/parsers");
const parsers_5 = require("api/references/funding/projects/parsers");
const parseProcurements = (response) => {
    const procurements = response.content;
    if (procurements === null)
        return { data: null, errors: response.errors, status: response.status };
    try {
        return {
            data: procurements.data.map(el => {
                const approvers = el.relationships.lastCorrection
                    ? el.relationships.lastCorrection.data.relationships.correctionItemWithOutContent.data.relationships.approvers.data
                    : el.relationships.approvers.data;
                const forceMajeure = el.relationships.lastCorrection?.data.relationships.correctionItemWithOutContent.data.attributes.hasForceMajeureComment
                    || el.attributes.hasForceMajeureComment;
                return {
                    id: el.id,
                    remainingItemsQuantity: el.attributes.remainingItemsQuantity,
                    hasForceMajeureComment: forceMajeure,
                    number: el.attributes.number,
                    sumPrice: el.attributes.sumPrice + ' GEL',
                    signedDocumentNumber: el.attributes.signedDocumentNumber,
                    procurementType: el.attributes.templateType,
                    dateOfConclusion: (0, parsers_2.nullableTransformDate)(el.attributes.dateOfConclusion),
                    shortDateOfConclusion: (0, parsers_2.nullableTransformDate)(el.attributes.dateOfConclusion, 'dd-MM-yy'),
                    status: el.attributes.status,
                    procurementVisualStatus: (0, exports.parseProcurementVisualStatus)(el.attributes.status),
                    subStatus: el.relationships.lastCorrection ?
                        (0, exports.parseProcurementSubStatus)(el.relationships.lastCorrection.data.relationships.correctionItemWithOutContent.data.attributes.subStatus)
                        : (0, exports.parseProcurementSubStatus)(el.attributes.subStatus),
                    correctionStatus: el.relationships.lastCorrection?.data.relationships.correctionItemWithOutContent.data.attributes.status,
                    correctionId: el.relationships.lastCorrection?.data.relationships.correctionItemWithOutContent.data.id,
                    correctionSumPrice: el.relationships.lastCorrection?.data.relationships.correctionItemWithOutContent.data.attributes.sumPrice + ' GEL',
                    correctionVisualStatus: el.relationships.lastCorrection
                        ? (0, exports.parseProcurementVisualStatus)(el.relationships.lastCorrection.data.relationships.correctionItemWithOutContent.data.attributes.status)
                        : { name: 'ხელმოსაწერად გაგზავნილი', color: 'info' },
                    createdAt: (0, parsers_2.transformDate)(el.attributes.createdAt),
                    shortCreatedAt: (0, parsers_2.transformDate)(el.attributes.createdAt, 'dd-MM-yy'),
                    companyName: el.relationships.company.data.attributes.name,
                    correctionItemsRemainingQuantity: el.relationships.lastCorrection?.data.relationships.correctionItemWithOutContent.data.attributes.remainingItemsQuantity || null,
                    correctionReasons: el.relationships.lastCorrection?.data.relationships.reasons.data.map(el => parsers_1.correctionReasons.find(reason => reason.id === String(el.attributes.reason))) || [],
                    creator: {
                        fullname: el.relationships.creator.data.relationships.user.data.attributes.fullname
                    },
                    approvers: approvers.map(approver => {
                        const structuralUnit = approver.relationships.account.data.relationships.contract.data.relationships.structuralUnit;
                        const project = approver.relationships.account.data.relationships.contract.data.relationships.project;
                        return {
                            id: approver.id,
                            approverId: approver.relationships.account.data.relationships.user.data.id,
                            accountId: approver.relationships.account.data.id,
                            status: approver.attributes.status,
                            structuralName: structuralUnit ? structuralUnit.data.attributes.name : 'პროექტის "' + project?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი',
                            vacation: null,
                            userName: approver.relationships.account.data.relationships.user.data.attributes.fullname,
                            deleteComment: approver.attributes.deleteComment,
                            comment: approver.attributes.status === types_1.ApproverStatus.DECLINED
                                ? approver.attributes.declineComment
                                : approver.attributes.status === types_1.ApproverStatus.SUSPENDED
                                    ? approver.attributes.suspendComment : null,
                            deletedAt: (0, parsers_2.nullableTransformDate)(approver.attributes.deletedAt)
                        };
                    })
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_2.transformPagination)(procurements.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseProcurements = parseProcurements;
const parseProcurement = (response, original) => {
    const procurement = response.content;
    if (procurement === null)
        return { data: null, errors: response.errors, status: response.status };
    const date = new Date();
    date.setDate(date.getDate() + 30);
    try {
        return {
            data: (0, exports.parseProcurementData)(procurement, original),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseProcurement = parseProcurement;
const parseUpdateProcurement = (response, original) => {
    const procurement = response.content;
    if (procurement === null)
        return { data: null, errors: response.errors, status: response.status };
    try {
        return {
            data: {
                ...(0, exports.parseProcurementData)(procurement, original),
                content: procurement.data.relationships.template ? procurement.data.relationships.template.data.attributes.content : null
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseUpdateProcurement = parseUpdateProcurement;
const parseProcurementItem = (data, date) => {
    const treasuryDate = new Date();
    treasuryDate.setDate(date.getDate() + 30);
    const treasuryAlertTime = data.relationships.treasuryProject && data.relationships.treasuryProject.data.attributes.endDate
        ? treasuryDate > new Date(data.relationships.treasuryProject.data.attributes.endDate)
        : false;
    // const verifyItem = (data.relationships.verifyItem && { data: data.relationships.verifyItem.data, procurementItemType: ProcurementItemType.VERIFY_ITEM })
    //   || ({ data: data.relationships.submissionItem as { data: ResponseProcurementSubmissionItem }, procurementItemType: ProcurementItemType.SUBMISSION_ITEM }).data
    const verifyItem1 = data.relationships.verifyItem || data.relationships.submissionItem;
    const submissionItem = data.relationships.verifyItem || data.relationships.submissionItem;
    const linkedItem = (verifyItem1 && { ...verifyItem1, procurementItemType: types_1.ProcurementItemType.VERIFY_ITEM })
        || (submissionItem && { ...submissionItem, procurementItemType: types_1.ProcurementItemType.SUBMISSION_ITEM });
    const specification = (linkedItem.data.relationships.inventoryConfiguration.data.attributes.specificationTitle
        ? ' - ' + linkedItem.data.relationships.inventoryConfiguration.data.attributes.specificationTitle : '')
        + (linkedItem.data.attributes.characteristics ? ' / ' + linkedItem.data.attributes.characteristics : '');
    const oldBudgetArticle = linkedItem.data.relationships.budgetArticle && {
        id: linkedItem.data.relationships.budgetArticle.data.id,
        name: linkedItem.data.relationships.budgetArticle.data.attributes.name,
        code: linkedItem.data.relationships.budgetArticle.data.attributes.code,
        label: linkedItem.data.relationships.budgetArticle.data.attributes.code + ' - ' + linkedItem.data.relationships.budgetArticle.data.attributes.name
    };
    const newBudgetArticle = (data.relationships.budgetArticle &&
        {
            id: data.relationships.budgetArticle.data.id,
            name: data.relationships.budgetArticle.data.attributes.name,
            code: data.relationships.budgetArticle.data.attributes.code,
            label: data.relationships.budgetArticle.data.attributes.code + ' - ' + data.relationships.budgetArticle.data.attributes.name
        });
    const oldCpvCode = {
        id: linkedItem.data.relationships.cpvCode.data.id,
        label: linkedItem.data.relationships.cpvCode.data.attributes.code + ' - ' + linkedItem.data.relationships.cpvCode.data.attributes.name,
        name: linkedItem.data.relationships.cpvCode.data.attributes.name,
        code: linkedItem.data.relationships.cpvCode.data.attributes.code
    };
    const newCpvCode = (data.relationships.cpvCode &&
        {
            id: data.relationships.cpvCode.data.id,
            label: data.relationships.cpvCode.data.attributes.code + ' - ' + data.relationships.cpvCode.data.attributes.name,
            name: data.relationships.cpvCode.data.attributes.name,
            code: data.relationships.cpvCode.data.attributes.code
        });
    const treasuryBudgetArticle = data.relationships.treasuryBudgetArticle &&
        {
            id: data.relationships.treasuryBudgetArticle.data.id,
            label: data.relationships.treasuryBudgetArticle.data.attributes.name
        };
    const treasuryProject = data.relationships.treasuryProject &&
        {
            id: data.relationships.treasuryProject.data.id,
            label: data.relationships.treasuryProject.data.attributes.name,
            alert: treasuryAlertTime
                ? 'პროექტის დასრულების დრო არის: ' + (0, parsers_2.nullableTransformDate)(data.relationships.treasuryProject.data.attributes.endDate)
                : undefined
        };
    const alertTime = linkedItem.data.relationships.project.data.attributes.endDate
        ? date > new Date(linkedItem.data.relationships.project.data.attributes.endDate)
        : false;
    const oldProject = {
        id: linkedItem.data.relationships.project.data.id,
        label: (0, parsers_5.parseProjectFullName)(linkedItem.data.relationships.project.data),
        shortName: (0, parsers_5.parseProjectShortName)(linkedItem.data.relationships.project.data),
        disabled: linkedItem.data.relationships.project.data.attributes.endDate ? Date.now() > Date.parse(linkedItem.data.relationships.project.data.attributes.endDate) : false,
        alert: alertTime
            ? 'პროექტის დასრულების დრო არის: ' + (0, parsers_2.nullableTransformDate)(linkedItem.data.relationships.project.data.attributes.endDate)
            : undefined
    };
    const newProject = data.relationships.project && {
        id: data.relationships.project.data.id,
        label: (0, parsers_5.parseProjectFullName)(data.relationships.project.data),
        shortName: (0, parsers_5.parseProjectShortName)(data.relationships.project.data),
        disabled: data.relationships.project.data.attributes.endDate ? Date.now() > Date.parse(data.relationships.project.data.attributes.endDate) : false,
        alert: alertTime
            ? 'პროექტის დასრულების დრო არის: ' + (0, parsers_2.nullableTransformDate)(data.relationships.project.data.attributes.endDate)
            : undefined
    };
    return {
        id: linkedItem.data.id,
        procurementItemType: linkedItem.procurementItemType,
        procurementItemId: data.id,
        invoices: data.relationships.invoiceItems?.data.map(el => {
            return {
                id: el.relationships.document.data.id,
                number: el.relationships.document.data.attributes.number,
                statusColor: (0, parsers_4.parseInvoiceVisualStatuses)(el.relationships.document.data.attributes.status).color
            };
        }) || [],
        purchaseSubject: data.attributes.inventoryItemTitle || linkedItem.data.relationships.inventoryConfiguration.data.attributes.itemTitle,
        specification,
        specificationShortName: (0, parsers_2.parseShortName)(specification),
        submissionNumber: data.relationships.submissionItem
            ? data.relationships.submissionItem.data.relationships.document.data.attributes.number
            : data.relationships.verifyItem.data.relationships.submission.data.attributes.number,
        submissionId: data.relationships.submissionItem ? data.relationships.submissionItem.data.relationships.document.data.id : null,
        verifySubmissionId: data.relationships.verifyItem ? data.relationships.verifyItem.data.relationships.document.data.id : null,
        cpvCode: newCpvCode || oldCpvCode,
        oldCpvCode,
        budgetArticle: newBudgetArticle || oldBudgetArticle,
        oldBudgetArticle,
        project: newProject || oldProject,
        oldProject,
        treasuryBudgetArticle,
        treasuryProject,
        dimensionUnit: linkedItem.data.relationships.dimensionUnit.data.attributes.name,
        remainingQuantity: data.attributes.remainingQuantity,
        quantity: data.attributes.quantity,
        oldQuantity: data.relationships.parentProcurementItem?.data.attributes.quantity,
        currencyExchangeRate: linkedItem.data.attributes.currencyExchangeRate,
        currencyRateQuantity: linkedItem.data.relationships.currency.data.attributes.quantity || 1,
        price: data.attributes.price,
        oldPrice: linkedItem.data.attributes.price,
        currency: linkedItem.data.relationships.currency.data.attributes.code,
        checked: false
    };
};
exports.parseProcurementItem = parseProcurementItem;
const parseProcurementItemsData = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const procurementItems = response.content;
    try {
        return {
            data: procurementItems.data.map(el => {
                const linkedItem = el.relationships.verifyItem || el.relationships.submissionItem;
                const currency = linkedItem.data.relationships.currency.data;
                const currencyRate = el.attributes.currencyExchangeRate || currency.attributes.rate;
                const transformedRate = currencyRate
                    ? currencyRate / (currency.attributes.quantity || 1)
                    : 1;
                return {
                    id: el.id,
                    moduleType: el.type,
                    name: linkedItem.data.relationships.inventoryConfiguration.data.attributes.itemTitle,
                    specification: linkedItem.data.relationships.inventoryConfiguration.data.attributes.specificationTitle || '',
                    quantity: linkedItem.data.attributes.quantity,
                    remainingQuantity: el.attributes.remainingQuantity,
                    dimensionUnit: linkedItem.data.relationships.dimensionUnit.data.attributes.name,
                    price: el.attributes.price,
                    currencyExchangeRate: linkedItem.data.attributes.currencyExchangeRate || 1,
                    currencyRate,
                    currency: {
                        id: currency.id,
                        label: currency.attributes.code,
                        currentExchangeRate: currency.attributes.rate,
                        quantity: currency.attributes.quantity,
                        transformedRate
                    }, createdAt: (0, parsers_2.transformDate)(el.attributes.createdAt),
                    updatedAt: (0, parsers_2.transformDate)(el.attributes.updatedAt)
                };
            }),
            meta: (0, parsers_2.transformPagination)(procurementItems.meta),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseProcurementItemsData = parseProcurementItemsData;
const parseProcurementCalculateItemsData = (response, procurementId) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const procurementItems = response.content;
    const submissions = [];
    let unusedProcurementSum = 0;
    let otherProcurementSum = 0;
    let alteredOtherProcurementSum = 0;
    let thisProcurementSum = 0;
    let alteredThisProcurementSum = 0;
    const items = procurementItems.data.map(el => {
        const { inventoryConfiguration, project, budgetArticle, document: verifySubmission, procurementItem, currency: responseCurrency, submission } = el.relationships;
        const procurementItemBudgetArticle = procurementItem?.data.relationships.budgetArticle;
        const alteredBudgetArticle = budgetArticle && procurementItemBudgetArticle && procurementItemBudgetArticle.data.id !== budgetArticle.data.id
            ? procurementItemBudgetArticle
            : null;
        const currency = {
            id: responseCurrency.data.id,
            name: responseCurrency.data.attributes.name,
            quantity: responseCurrency.data.attributes.quantity || 1,
            rate: responseCurrency.data.attributes.rate || 1,
            code: responseCurrency.data.attributes.code
        };
        const currencyRate = (el.attributes.currencyExchangeRate || 1) / currency.quantity;
        const elSum = el.attributes.quantity * el.attributes.price * currencyRate;
        const alteredElSum = procurementItem ? el.attributes.quantity * procurementItem.data.attributes.price * currencyRate : elSum;
        const procurement = procurementItem?.data.relationships.documentWithOnlyNumber.data;
        const submissionProcurementId = procurement && procurement.id;
        const submissionIndex = submissions.findIndex(item => item.id === verifySubmission.data.id);
        if (submissionIndex === -1) {
            submissions.push({
                id: verifySubmission.data.id,
                label: submission.data.attributes.number,
                sum: submissionProcurementId === procurementId ? elSum : 0,
                alteredSum: submissionProcurementId === procurementId ? alteredElSum : 0,
                otherProcurementSum: submissionProcurementId && submissionProcurementId !== procurementId ? elSum : 0,
                alteredOtherProcurementSum: submissionProcurementId && submissionProcurementId !== procurementId ? alteredElSum : 0,
                unusedProcurementSum: !submissionProcurementId ? elSum : 0
            });
        }
        else {
            const headerSubmission = submissions[submissionIndex];
            submissions[submissionIndex] = {
                ...headerSubmission,
                sum: submissionProcurementId === procurementId ? headerSubmission.sum + elSum : headerSubmission.sum,
                alteredSum: submissionProcurementId === procurementId ? headerSubmission.alteredSum + alteredElSum : headerSubmission.alteredSum,
                otherProcurementSum: submissionProcurementId && submissionProcurementId !== procurementId
                    ? headerSubmission.otherProcurementSum + elSum
                    : headerSubmission.otherProcurementSum,
                alteredOtherProcurementSum: submissionProcurementId && submissionProcurementId !== procurementId
                    ? headerSubmission.alteredOtherProcurementSum + alteredElSum
                    : headerSubmission.alteredOtherProcurementSum,
                unusedProcurementSum: !submissionProcurementId ? headerSubmission.unusedProcurementSum + elSum : headerSubmission.unusedProcurementSum
            };
        }
        if (!procurement) {
            unusedProcurementSum = unusedProcurementSum + elSum;
        }
        else if (procurement.id === procurementId) {
            thisProcurementSum = thisProcurementSum + elSum;
            alteredThisProcurementSum = alteredThisProcurementSum + alteredElSum;
        }
        else {
            otherProcurementSum = otherProcurementSum + elSum;
            alteredOtherProcurementSum = alteredOtherProcurementSum + alteredElSum;
        }
        return {
            id: el.id,
            name: inventoryConfiguration.data.attributes.itemTitle,
            quantity: el.attributes.quantity,
            currencyExchangeRate: el.attributes.currencyExchangeRate || 1,
            currency,
            project: { id: project.data.id, label: project.data.attributes.name },
            budgetArticle: (budgetArticle && { id: budgetArticle.data.id, label: budgetArticle.data.attributes.code + ' - ' + budgetArticle.data.attributes.name }) || null,
            submission: { id: verifySubmission.data.id, number: submission.data.attributes.number },
            price: el.attributes.price,
            alteredPrice: procurementItem?.data.attributes.price,
            procurement: (procurement && { id: procurement.id, number: procurement.attributes.number }) || null,
            alteredBudgetArticle: (alteredBudgetArticle && { id: alteredBudgetArticle.data.id, label: alteredBudgetArticle.data.attributes.name }) || null
        };
    });
    const groupByProjects = items.reduce((result, value) => {
        const project = result.find(item => item.id === value.project.id);
        const sum = value.price * value.quantity * (value.currencyExchangeRate / value.currency.quantity);
        const alteredSum = (value.alteredPrice || value.price) * value.quantity * (value.currencyExchangeRate / value.currency.quantity);
        const valueProcurementId = value.procurement && value.procurement.id;
        if (project) {
            project.sum = project.sum + sum;
            project.alteredSum = project.alteredSum + alteredSum;
            project.items = project.items.concat(value);
            const submissionSumIndex = project.submissionsSum.findIndex(submissionSum => submissionSum.id === value.submission.id);
            if (submissionSumIndex === -1) {
                project.submissionsSum = project.submissionsSum.concat({
                    id: value.submission.id,
                    sum: valueProcurementId && valueProcurementId === procurementId ? sum : 0,
                    alteredSum: valueProcurementId && valueProcurementId === procurementId ? alteredSum : 0,
                    otherProcurementSum: valueProcurementId && valueProcurementId !== procurementId ? sum : 0,
                    alteredOtherProcurementSum: valueProcurementId && valueProcurementId !== procurementId ? alteredSum : 0,
                    unusedProcurementSum: !valueProcurementId ? sum : 0
                });
            }
            else {
                const submissionSum = project.submissionsSum[submissionSumIndex];
                project.submissionsSum[submissionSumIndex] = {
                    ...submissionSum,
                    sum: valueProcurementId && valueProcurementId === procurementId ? submissionSum.sum + sum : submissionSum.sum,
                    alteredSum: valueProcurementId && valueProcurementId === procurementId ? submissionSum.alteredSum + alteredSum : submissionSum.alteredSum,
                    otherProcurementSum: valueProcurementId && valueProcurementId !== procurementId ? submissionSum.otherProcurementSum + sum : submissionSum.otherProcurementSum,
                    alteredOtherProcurementSum: valueProcurementId && valueProcurementId !== procurementId ? submissionSum.alteredOtherProcurementSum + alteredSum : submissionSum.alteredOtherProcurementSum,
                    unusedProcurementSum: !valueProcurementId ? submissionSum.unusedProcurementSum + sum : submissionSum.unusedProcurementSum
                };
            }
            if (valueProcurementId === procurementId) {
                project.procurementSum = project.procurementSum + sum;
                project.alteredProcurementSum = project.alteredProcurementSum + alteredSum;
            }
        }
        else {
            result.push({
                ...value.project,
                submissionsSum: [
                    {
                        id: value.submission.id,
                        // sum
                        sum: valueProcurementId && valueProcurementId === procurementId ? sum : 0,
                        alteredSum: valueProcurementId && valueProcurementId === procurementId ? alteredSum : 0,
                        otherProcurementSum: valueProcurementId && valueProcurementId !== procurementId ? sum : 0,
                        alteredOtherProcurementSum: valueProcurementId && valueProcurementId !== procurementId ? alteredSum : 0,
                        unusedProcurementSum: !valueProcurementId ? sum : 0
                    }
                ],
                procurementSum: valueProcurementId === procurementId ? sum : 0,
                alteredProcurementSum: valueProcurementId === procurementId ? alteredSum : 0,
                sum,
                alteredSum,
                currencyExchangeRate: value.currencyExchangeRate,
                items: [value]
            });
        }
        return result;
    }, []);
    const groupedByBudgetArticles = groupByProjects.reduce((result, value) => {
        const project = {
            id: value.id,
            label: value.label,
            currencyExchangeRate: value.currencyExchangeRate,
            procurementSum: value.procurementSum,
            alteredProcurementSum: value.alteredProcurementSum,
            submissionsSum: value.submissionsSum,
            sum: value.sum,
            alteredSum: value.alteredSum,
            budgetArticles: value.items.reduce((budgetArticlesResult, budgetArticleValue) => {
                const articleProcurementId = budgetArticleValue.procurement?.id;
                const budgetArticle = budgetArticlesResult.find(item => item.id === budgetArticleValue.budgetArticle?.id);
                const sum = budgetArticleValue.price * budgetArticleValue.quantity * (budgetArticleValue.currencyExchangeRate / budgetArticleValue.currency.quantity);
                const alteredSum = (budgetArticleValue.alteredPrice || budgetArticleValue.price) * budgetArticleValue.quantity * (budgetArticleValue.currencyExchangeRate / budgetArticleValue.currency.quantity);
                if (budgetArticle) {
                    budgetArticle.sum = budgetArticle.sum + sum;
                    budgetArticle.alteredSum = budgetArticle.alteredSum + alteredSum;
                    const submissionSumIndex = budgetArticle.submissionsSum.findIndex(submissionSum => submissionSum.id === budgetArticleValue.submission.id);
                    if (submissionSumIndex === -1) {
                        budgetArticle.submissionsSum = budgetArticle.submissionsSum.concat({
                            id: budgetArticleValue.submission.id,
                            sum: articleProcurementId && articleProcurementId === procurementId ? sum : 0,
                            alteredSum: articleProcurementId && articleProcurementId === procurementId ? alteredSum : 0,
                            otherProcurementSum: articleProcurementId && articleProcurementId !== procurementId ? sum : 0,
                            alteredOtherProcurementSum: articleProcurementId && articleProcurementId !== procurementId ? alteredSum : 0,
                            unusedProcurementSum: !articleProcurementId ? sum : 0
                        });
                    }
                    else {
                        const submissionSum = budgetArticle.submissionsSum[submissionSumIndex];
                        budgetArticle.submissionsSum[submissionSumIndex] = {
                            ...submissionSum,
                            sum: articleProcurementId && articleProcurementId === procurementId ? submissionSum.sum + sum : submissionSum.sum,
                            alteredSum: articleProcurementId && articleProcurementId === procurementId ? submissionSum.alteredSum + alteredSum : submissionSum.alteredSum,
                            otherProcurementSum: articleProcurementId && articleProcurementId !== procurementId ? submissionSum.otherProcurementSum + sum : submissionSum.otherProcurementSum,
                            alteredOtherProcurementSum: articleProcurementId && articleProcurementId !== procurementId ? submissionSum.alteredOtherProcurementSum + alteredSum : submissionSum.alteredOtherProcurementSum,
                            unusedProcurementSum: !articleProcurementId ? submissionSum.unusedProcurementSum + sum : submissionSum.unusedProcurementSum
                        };
                    }
                    if (articleProcurementId === procurementId && budgetArticleValue.alteredBudgetArticle === null) {
                        budgetArticle.procurementSum = budgetArticle.procurementSum + sum;
                        budgetArticle.alteredProcurementSum = budgetArticle.alteredProcurementSum + alteredSum;
                    }
                }
                else {
                    budgetArticlesResult.push({
                        id: budgetArticleValue.budgetArticle?.id || '0',
                        label: budgetArticleValue.budgetArticle?.label || 'unknown',
                        submissionsSum: [
                            {
                                id: budgetArticleValue.submission.id,
                                sum: articleProcurementId && articleProcurementId === procurementId ? sum : 0,
                                alteredSum: articleProcurementId && articleProcurementId === procurementId ? alteredSum : 0,
                                otherProcurementSum: articleProcurementId && articleProcurementId !== procurementId ? sum : 0,
                                alteredOtherProcurementSum: articleProcurementId && articleProcurementId !== procurementId ? alteredSum : 0,
                                unusedProcurementSum: !articleProcurementId ? sum : 0
                            }
                        ],
                        currencyExchangeRate: budgetArticleValue.currencyExchangeRate,
                        procurementSum: articleProcurementId === procurementId && budgetArticleValue.alteredBudgetArticle === null ? sum : 0,
                        alteredProcurementSum: articleProcurementId === procurementId && budgetArticleValue.alteredBudgetArticle === null ? alteredSum : 0,
                        alteredSum,
                        sum
                    });
                }
                if (budgetArticleValue.alteredBudgetArticle !== null) {
                    const alteredBudgetArticle = budgetArticlesResult.find(item => item.id === budgetArticleValue.alteredBudgetArticle.id);
                    if (!alteredBudgetArticle) {
                        budgetArticlesResult.push({
                            id: budgetArticleValue.alteredBudgetArticle?.id || '0',
                            label: budgetArticleValue.alteredBudgetArticle?.label || 'unknown',
                            submissionsSum: [],
                            currencyExchangeRate: budgetArticleValue.currencyExchangeRate,
                            procurementSum: articleProcurementId === procurementId ? sum : 0,
                            alteredProcurementSum: articleProcurementId === procurementId ? alteredSum : 0,
                            alteredSum,
                            sum
                        });
                    }
                    else {
                        alteredBudgetArticle.sum = alteredBudgetArticle.sum + sum;
                        alteredBudgetArticle.alteredSum = alteredBudgetArticle.alteredSum + alteredSum;
                        if (articleProcurementId === procurementId) {
                            alteredBudgetArticle.procurementSum = alteredBudgetArticle.procurementSum + sum;
                            alteredBudgetArticle.alteredProcurementSum = alteredBudgetArticle.alteredProcurementSum + alteredSum;
                        }
                    }
                }
                return budgetArticlesResult;
            }, [])
        };
        result.push(project);
        return result;
    }, []);
    return {
        data: {
            projects: groupedByBudgetArticles,
            submissions,
            thisProcurementSum,
            alteredOtherProcurementSum,
            alteredThisProcurementSum,
            otherProcurementSum,
            unusedProcurementSum
            // unusedProcurements
        },
        status: response.status,
        errors: null
    };
};
exports.parseProcurementCalculateItemsData = parseProcurementCalculateItemsData;
const parseProcurementFiles = (files) => {
    if (!files)
        return [];
    return files.map(el => {
        return {
            id: el.id,
            name: el.attributes.name,
            link: el.attributes.downloadUrl,
            type: el.attributes.pivots.type,
            status: el.attributes.status,
            originalName: el.attributes.originalName,
            isOther: el.attributes.pivots.is_other === apiGlobalTypes_1.YesNoStatus.YES,
            extension: el.attributes.extension
        };
    });
};
exports.parseProcurementFiles = parseProcurementFiles;
const parseProcurementApprovers = (data) => {
    if (!data)
        return [];
    return data.map(approver => (0, exports.parseProcurementApprover)(approver));
};
exports.parseProcurementApprovers = parseProcurementApprovers;
const parseProcurementApprover = (approver) => {
    const structuralUnit = approver.relationships.account.data.relationships.contract.data.relationships.structuralUnit;
    const project = approver.relationships.account.data.relationships.contract.data.relationships.project;
    const email = approver.relationships.account.data.relationships.user.data.attributes.email
        ? ' (' + approver.relationships.account.data.relationships.user.data.attributes.email + ')'
        : '';
    return {
        id: approver.relationships.account.data.relationships.user.data.id,
        approverId: approver.id,
        accountId: approver.relationships.account.data.id,
        userName: approver.relationships.account.data.relationships.user.data.attributes.fullname + email,
        structuralName: structuralUnit ? structuralUnit.data.attributes.fullName : 'პროექტის "' + project?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი',
        vacation: approver.relationships.account.data.relationships.vacation?.data.relationships.substituteAccount.data.relationships.user.data.attributes.fullname || null,
        status: approver.attributes.status,
        deleteComment: approver.attributes.deleteComment,
        comment: approver.attributes.status === types_1.ApproverStatus.DECLINED
            ? approver.attributes.declineComment
            : approver.attributes.status === types_1.ApproverStatus.SUSPENDED
                ? approver.attributes.suspendComment : null,
        deletedAt: (0, parsers_2.nullableTransformDate)(approver.attributes.deletedAt)
    };
};
exports.parseProcurementApprover = parseProcurementApprover;
const parseVerifiedItems = (response) => {
    const verifiedItems = response.content;
    if (verifiedItems === null)
        return { data: null, errors: response.errors, status: response.status };
    const date = new Date();
    date.setDate(date.getDate() + 30);
    try {
        return {
            data: verifiedItems.data.map(el => {
                const alertTime = el.relationships.project.data.attributes.endDate
                    ? date > new Date(el.relationships.project.data.attributes.endDate)
                    : false;
                const cpvCode = {
                    id: el.relationships.cpvCode.data.id,
                    label: el.relationships.cpvCode.data.attributes.code + ' - ' + el.relationships.cpvCode.data.attributes.name,
                    name: el.relationships.cpvCode.data.attributes.name,
                    code: el.relationships.cpvCode.data.attributes.code
                };
                const budgetArticle = {
                    id: el.relationships.budgetArticle?.data.id,
                    name: el.relationships.budgetArticle?.data.attributes.name,
                    code: el.relationships.budgetArticle?.data.attributes.code,
                    label: el.relationships.budgetArticle?.data.attributes.code + ' - ' + el.relationships.budgetArticle?.data.attributes.name
                };
                const project = {
                    id: el.relationships.project.data.id,
                    label: (0, parsers_5.parseProjectFullName)(el.relationships.project.data),
                    alert: alertTime ? 'პროექტის დასრულების დრო არის: ' + (0, parsers_2.nullableTransformDate)(el.relationships.project.data.attributes.endDate) : undefined
                };
                const specification = (el.relationships.inventoryConfiguration.data.attributes.specificationTitle
                    ? ' - ' + el.relationships.inventoryConfiguration.data.attributes.specificationTitle : '')
                    + (el.attributes.characteristics ? ' / ' + el.attributes.characteristics : '');
                return {
                    id: el.id,
                    purchaseSubject: el.relationships.inventoryConfiguration.data.attributes.itemTitle,
                    specification,
                    specificationShortName: (0, parsers_2.parseShortName)(specification),
                    submissionNumber: el.relationships.submission.data.attributes.number,
                    submissionId: el.relationships.submission.data.id,
                    cpvCode,
                    verifySubmissionId: el.relationships.document.data.id,
                    oldCpvCode: cpvCode,
                    hasSubmissionItem: false,
                    budgetArticle,
                    oldBudgetArticle: budgetArticle,
                    project,
                    remainingQuantity: el.attributes.remainingQuantity,
                    oldProject: project,
                    dimensionUnit: el.relationships.dimensionUnit.data.attributes.name,
                    quantity: el.attributes.quantity,
                    currencyExchangeRate: el.attributes.currencyExchangeRate,
                    currencyRateQuantity: el.relationships.currency.data.attributes.quantity || 1,
                    price: el.attributes.price,
                    oldPrice: el.attributes.price,
                    currency: el.relationships.currency.data.attributes.code,
                    checked: false
                };
            }),
            status: response.status,
            meta: (0, parsers_2.transformPagination)(verifiedItems.meta),
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseVerifiedItems = parseVerifiedItems;
const parseApprovedItemCpvCodesForSelect = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors };
    const cpvCodes = response.content;
    try {
        return {
            data: cpvCodes.data.map(el => ({
                id: el.id,
                label: el.attributes.name
            })),
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseApprovedItemCpvCodesForSelect = parseApprovedItemCpvCodesForSelect;
const parseApprovedItemSubmissionsForSelect = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors };
    const submissions = response.content;
    try {
        return {
            data: submissions.data.map(el => ({
                id: el.id,
                label: el.attributes.number
            })),
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseApprovedItemSubmissionsForSelect = parseApprovedItemSubmissionsForSelect;
const parseSuggestedApprovals = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const employees = response.content;
    try {
        return {
            data: employees.data.map(el => {
                const email = el.attributes.email ? ' (' + el.attributes.email + ')' : '';
                return {
                    id: el.id,
                    name: el.attributes.firstname + ' ' + el.attributes.lastname + email,
                    positions: el.relationships.accounts.data.filter(item => !!item.relationships.contract.data.relationships.structuralUnit).map(account => {
                        const structuralUnit = account.relationships.contract.data.relationships.structuralUnit;
                        return {
                            accountId: account.id,
                            id: structuralUnit.data.id,
                            label: structuralUnit.data.attributes.name
                                + (account.relationships.vacation ? ' (იმყოფება შვებულებაში,'
                                    + ` შემცვლელი: ${account.relationships.vacation.data.relationships.substituteAccount.data.relationships.user.data.attributes.fullname})` : ''),
                            disabled: !!account.relationships.vacation
                        };
                    })
                        || [],
                    position: null
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseSuggestedApprovals = parseSuggestedApprovals;
const parseProcurementsForSelect = (response) => {
    if (response.content === null)
        return [];
    const procurements = response.content;
    try {
        return procurements.data.map(el => {
            return {
                id: el.id,
                label: el.attributes.signedDocumentNumber || el.attributes.number,
                contractType: {
                    id: el.relationships.type.data.id,
                    label: el.relationships.type.data.attributes.name
                }
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseProcurementsForSelect = parseProcurementsForSelect;
const parseExportProcurementItems = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const procurementFile = response.content.data;
    try {
        return {
            data: (0, parsers_3.parseFileData)(procurementFile),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseExportProcurementItems = parseExportProcurementItems;
const parseProcurementItems = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const procurement = response.content;
    const date = new Date();
    try {
        return {
            data: procurement.data.map(item => (0, exports.parseProcurementItem)(item, date)),
            meta: procurement.meta ? (0, parsers_2.transformPagination)(procurement.meta) : undefined,
            errors: null,
            status: response.status
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseProcurementItems = parseProcurementItems;
const parseProcurementTemplate = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const template = response.content;
    try {
        return {
            data: {
                content: template.data.attributes.content
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseProcurementTemplate = parseProcurementTemplate;
const parseProcurementData = (procurement, original) => {
    const status = procurement.data.attributes.status;
    const companyAddress = procurement.data.relationships.companyAddress?.data;
    const companyBankAccount = procurement.data.relationships.companyBankAccount?.data;
    const companySignatory = procurement.data.relationships.companySignatory?.data;
    const company = procurement.data.relationships.company.data;
    const procurementFiles = (0, exports.parseProcurementFiles)(procurement.data.relationships.mediaFiles?.data);
    const todayInMs = new Date().setHours(0, 0, 0, 0);
    const contractValidityDateInMs = new Date(procurement.data.attributes.contractValidityDate).setHours(0, 0, 0, 0);
    const forceMajeure = procurement.data.relationships.lastCorrection
        ? procurement.data.relationships.lastCorrection.data.attributes.hasForceMajeureComment
        : procurement.data.attributes.hasForceMajeureComment;
    return {
        id: procurement.data.id,
        hasForceMajeureComment: forceMajeure,
        moduleType: procurement.data.type,
        number: procurement.data.attributes.number,
        disableCorrectionCreate: todayInMs > contractValidityDateInMs || !!procurement.data.attributes.actuallyDoneComment,
        signedDocumentNumber: procurement.data.attributes.signedDocumentNumber,
        dateOfConclusion: (0, parsers_2.nullableTransformDate)(procurement.data.attributes.dateOfConclusion),
        deliveryDate: (0, parsers_2.transformDate)(procurement.data.attributes.deliveryDate),
        contractValidityDate: (0, parsers_2.transformDate)(procurement.data.attributes.contractValidityDate),
        advanceGuaranteeValidityPeriod: (0, parsers_2.nullableTransformDate)(procurement.data.attributes.advanceGuaranteeValidityPeriod),
        contractGuaranteeValidityPeriod: (0, parsers_2.nullableTransformDate)(procurement.data.attributes.contractGuaranteeValidityPeriod),
        goodsGuaranteeValidityPeriod: (0, parsers_2.nullableTransformDate)(procurement.data.attributes.goodsGuaranteeValidityPeriod),
        templateType: procurement.data.attributes.templateType,
        declineMessage: procurement.data.attributes.declineMessage,
        actuallyDoneComment: procurement.data.attributes.actuallyDoneComment,
        sumPrice: procurement.data.attributes.sumPrice,
        tenderLink: procurement.data.attributes.tenderLink || '',
        cmr: procurement.data.attributes.cmr || '',
        spa: procurement.data.attributes.spa || '',
        con: procurement.data.attributes.con || '',
        isOther: procurement.data.relationships.mediaFiles?.data.find(file => file.attributes.pivots.type === types_1.TemplateTypes.ELECTRONIC
            || file.attributes.pivots.type === types_1.TemplateTypes.SIMPLIFIED
            || file.attributes.pivots.type === types_1.TemplateTypes.CONSOLIDATED)?.attributes.pivots.is_other === apiGlobalTypes_1.YesNoStatus.YES,
        procurementVisualStatus: (0, exports.parseProcurementVisualStatus)(procurement.data.attributes.status),
        subVisualStatus: (0, exports.parseProcurementSubStatus)(procurement.data.attributes.subStatus),
        subStatus: procurement.data.attributes.subStatus,
        creator: {
            id: procurement.data.relationships.creator.data.relationships.user.data.id,
            accountId: procurement.data.relationships.creator.data.id,
            fullname: procurement.data.relationships.creator.data.relationships.user.data.attributes.fullname
        },
        revertComment: procurement.data.attributes.revertComment,
        companySignatories: company.relationships?.signatories?.data.map(el => {
            return {
                id: el.id,
                label: el.attributes.firstname + ' ' + el.attributes.lastname,
                phones: el.relationships.phones?.data.map(phone => {
                    return {
                        id: phone.id,
                        label: phone.attributes.phone
                    };
                })
            };
        }),
        remainingItemsQuantity: procurement.data.attributes.remainingItemsQuantity,
        type: procurement.data.relationships.type.data && {
            id: procurement.data.relationships.type.data.id,
            label: procurement.data.relationships.type.data.attributes.name
        },
        company: company && {
            id: company.id,
            label: company.attributes.name + ' - ' + company.attributes.legalStatusShortName,
            bankAccounts: company.relationships.bankAccounts.data.map(bankAccount => ({
                id: bankAccount.id,
                label: bankAccount.attributes.accountNumber,
                disabled: !bankAccount.attributes.status
            })),
            addresses: company.relationships.addresses.data.map(address => ({
                id: address.id,
                label: address.attributes.address,
                disabled: !address.attributes.isActive
            })),
            signatories: company.relationships.signatories.data.map(signatory => ({
                id: signatory.id,
                label: signatory.attributes.firstname + ' ' + signatory.attributes.lastname,
                phones: signatory.relationships.phones?.data.map(phone => ({
                    id: phone.id,
                    label: phone.attributes.phone
                })) || []
            }))
        },
        companyBankAccount: companyBankAccount && {
            id: companyBankAccount.id,
            label: companyBankAccount.attributes.accountNumber
        } || null,
        companyAddress: companyAddress && {
            id: companyAddress.id,
            label: companyAddress.attributes.address
        } || null,
        companySignatory: companySignatory && {
            id: companySignatory.id,
            label: companySignatory.attributes.firstname + ' ' + companySignatory.attributes.lastname
        } || null,
        files: procurementFiles.filter(el => el.type === types_1.TemplateTypes.ADDITIONAL_FILES || el.type === null),
        attachedFiles: procurementFiles.filter(el => el.type === types_1.TemplateTypes.SIGNATURE_DOCUMENT),
        attachedSignedFiles: procurementFiles.filter(el => el.type === types_1.TemplateTypes.SIGNED),
        approvers: (0, exports.parseProcurementApprovers)(procurement.data.relationships.approvers.data),
        procurementFile: procurementFiles.find(el => el.type === types_1.TemplateTypes.ELECTRONIC || el.type === types_1.TemplateTypes.SIMPLIFIED || el.type === types_1.TemplateTypes.CONSOLIDATED) || null,
        createdAt: procurement.data.attributes.createdAt,
        updatedAt: procurement.data.attributes.updatedAt,
        status,
        legalBasis: procurement.data.relationships.legalBasis ? {
            id: procurement.data.relationships.legalBasis.data.id,
            label: procurement.data.relationships.legalBasis.data.attributes.text
        } : null,
        textFieldForLegalBasis: '',
        guaranteeArticles: procurement.data.relationships.guaranteeArticles?.data.map(guaranteeArticle => ({
            id: guaranteeArticle.id,
            label: guaranteeArticle.attributes.title
        })) || [],
        lock: status !== types_1.ProcurementStatuses.DRAFT
            && status !== types_1.ProcurementStatuses.READY_FOR_CORRECTION
            && status !== types_1.ProcurementStatuses.APPROVAL_PROGRESS
            && status !== types_1.ProcurementStatuses.REVERTED
            && status !== types_1.ProcurementStatuses.SUSPENDED,
        original: original
    };
};
exports.parseProcurementData = parseProcurementData;
const parseProcurementVisualStatus = (status) => {
    switch (status) {
        case types_1.ProcurementStatuses.DRAFT:
            return { name: 'პროექტი', color: 'secondary' };
        case types_1.ProcurementStatuses.APPROVAL_PROGRESS:
            return { name: 'ვიზირების პროცესში', color: 'info' };
        case types_1.ProcurementStatuses.SIGN_PROGRESS:
            return { name: 'ხელმოწერის პროცესში', color: 'primary' };
        case types_1.ProcurementStatuses.DECLINED:
            return { name: 'უარყოფილი', color: 'danger' };
        case types_1.ProcurementStatuses.SIGNED:
            return { name: 'ხელმოწერილი ', color: 'success' };
        case types_1.ProcurementStatuses.CORRECTED:
            return { name: 'კორექტირებული', color: 'warning' };
        case types_1.ProcurementStatuses.READY_FOR_CORRECTION:
            return { name: 'პროექტი', color: 'secondary' };
        case types_1.ProcurementStatuses.CORRECTION_PROCESS:
            return { name: 'ხელმოწერილი', color: 'success' };
        case types_1.ProcurementStatuses.REVERTED:
            return { name: 'დაბრუნებული', color: 'info' };
        case types_1.ProcurementStatuses.SUSPENDED:
            return { name: 'შეჩერებული', color: 'warning' };
        case types_1.ProcurementStatuses.SENT_FOR_SIGNATURE:
            return { name: 'ხელმოსაწერად გაგზავნილი', color: 'info' };
    }
};
exports.parseProcurementVisualStatus = parseProcurementVisualStatus;
const parseProcurementSubStatus = (status) => {
    switch (status) {
        case types_1.ProcurementSubStatuses.EXECUTED:
            return { name: 'შესრულებული', color: 'success' };
        case types_1.ProcurementSubStatuses.ACTUALLY_DONE:
            return { name: 'ფაქტობრივად შესრულებული', color: 'warning' };
        case types_1.ProcurementSubStatuses.NOT_EXECUTED:
            return { name: 'შეუსრულებელი', color: 'secondary' };
        case types_1.ProcurementSubStatuses.PARTIALLY_EXECUTED:
            return { name: 'ნაწილობრივ შესრულებული', color: 'primary' };
    }
};
exports.parseProcurementSubStatus = parseProcurementSubStatus;
const procurementTypes = (translate) => {
    return [
        { id: String(types_1.ContractType.GOODS), label: translate('goods', 'საქონელი') },
        { id: String(types_1.ContractType.SERVICE), label: translate('service', 'მომსახურება') },
        { id: String(types_1.ContractType.JOB), label: translate('work', 'სამუშაო') },
        { id: String(types_1.ContractType.GOODS_WITH_SERVICES), label: translate('goods_with_accompanying_services', 'საქონელი თანმდევი მომსახურებით') },
        { id: String(types_1.ContractType.CORRECTION), label: translate('correction', 'ცვლილება') },
        { id: String(types_1.ContractType.CONTRACT_TYPE_GOODS_WITH_GUARANTEE), label: translate('good_under_warranty', 'საქონელი გარანტიის მუხლით') }
    ];
};
exports.procurementTypes = procurementTypes;
exports.templateTypes = [
    { id: String(types_1.TemplateTypes.ELECTRONIC), label: 'ელექტრონული' },
    { id: String(types_1.TemplateTypes.SIMPLIFIED), label: 'გამარტივებული' },
    { id: String(types_1.TemplateTypes.CONSOLIDATED), label: 'კონსოლიდირებული' }
];
const approverStatuses = (translate) => {
    return [
        { id: String(types_1.ApproverStatus.PENDING), label: translate('awaiting_reply', 'პასუხის მოლოდინში') },
        { id: String(types_1.ApproverStatus.APPROVED), label: translate('button_confirmed', 'დადასტურებული') },
        { id: String(types_1.ApproverStatus.DECLINED), label: translate('button_declined', 'უარყოფილი') },
        { id: String(types_1.ApproverStatus.SUSPENDED), label: translate('button_suspended', 'შეჩერებული') }
    ];
};
exports.approverStatuses = approverStatuses;
