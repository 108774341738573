"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rawMenuItems = void 0;
const rawMenuItems = (translate) => ([
    {
        name: translate('main', 'მთავარი'),
        url: '/dashboard',
        icon: 'fas fa-home',
        permission: 'public',
        children: []
    },
    {
        name: translate('menu_documents', 'დოკუმენტები'),
        url: '/documents',
        icon: 'fas fa-file-invoice',
        permission: 'cascade',
        children: [
            {
                name: translate('menu_purchases', 'შესყიდვები'),
                url: '/documents/purchases',
                icon: 'fas fa-home',
                permission: 'cascade',
                children: [
                    {
                        name: translate('menu_conditional_submissions', 'პირობითი წარდგინება'),
                        url: '/documents/purchases/conditional-submissions',
                        icon: 'fas fa-home',
                        permission: 'purchaseConditionalSubmissionsShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_submissions', 'წარდგინება'),
                        url: '/documents/purchases/submissions',
                        icon: 'fas fa-home',
                        permission: 'purchaseSubmissionsShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_submission_verifies', 'წარდგინების გადამოწმება'),
                        url: '/documents/purchases/submission-verifies',
                        icon: 'fas fa-home',
                        permission: 'purchaseSubmissionVerifiesShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_procurements', 'შესყიდვა/ხელშეკრულება'),
                        url: '/documents/purchases/procurements',
                        icon: 'fas fa-home',
                        permission: 'procurementsShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_invoices', 'მიღება-ჩაბარება / ინვოისი / ანგარიშფაქტურა'),
                        url: '/documents/purchases/invoices',
                        icon: 'fas fa-home',
                        permission: 'invoicesShowModule',
                        children: []
                    }
                ]
            },
            {
                name: translate('menu_human_resources', 'ადამიანური რესურსები'),
                url: '/documents/human-resources',
                icon: 'fas fa-home',
                permission: 'cascade',
                children: [
                    {
                        name: translate('menu_assign_submissions', 'დანიშვნის პირობითი წარდგინება'),
                        url: '/documents/human-resources/assign-submissions',
                        icon: 'fas fa-home',
                        permission: 'assignSubmissionsShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_assign_submission_verifies', 'დანიშვნის წარდგინების გადამოწმება'),
                        url: '/documents/human-resources/assign-submission-verifies',
                        icon: 'fas fa-home',
                        permission: 'assignSubmissionVerifiesShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_vacations', 'შვებულებები'),
                        url: '/documents/human-resources/vacations',
                        icon: 'fas fa-home',
                        permission: 'vacationsShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_assign_contracts', 'შრომითი ხელშეკრულებები'),
                        url: '/documents/human-resources/assign-contracts',
                        icon: 'fas fa-home',
                        permission: 'assignContractsShowModule',
                        children: []
                    }
                ]
            },
            {
                name: translate('menu_administrative_orders', 'ადმინისტრაციული ბრძანებები'),
                url: '/documents/administrative-orders',
                icon: 'fas fa-home',
                permission: 'administrativeOrdersShowModule',
                children: []
            }
        ]
    },
    {
        name: translate('menu_reports', 'ანგარიშგებები'),
        url: '/reports',
        icon: 'fas fa-chart-line',
        permission: 'cascade',
        children: [
            {
                name: translate('menu_purchases', 'შესყიდვები'),
                url: '/reports/purchases',
                icon: 'fas fa-home',
                permission: 'cascade',
                children: [
                    {
                        name: translate('menu_statistics', 'სტატისტიკები'),
                        url: '/reports/purchases/statistics',
                        icon: 'fas fa-home',
                        permission: 'procurementsReport',
                        children: []
                    }
                ]
            }
        ]
    },
    {
        name: translate('menu_references', 'ცნობარები'),
        url: '/references',
        icon: 'fas fa-folder-open',
        permission: 'cascade',
        children: [
            {
                name: translate('menu_employees', 'თანამშრომლები'),
                url: '/references/employees',
                icon: 'fas fa-chart-line',
                permission: 'usersShowModule',
                children: []
            },
            {
                name: translate('menu_companies', 'კომპანიები'),
                url: '/references/companies',
                icon: 'fas fa-chart-line',
                permission: 'companiesShowModule',
                children: []
            },
            {
                name: translate('menu_banks', 'ბანკები'),
                url: '/references/banks',
                icon: 'fas fa-chart-line',
                permission: 'banksShowModule',
                children: []
            },
            {
                name: translate('menu_holidays', 'დასვენების დღეები'),
                url: '/references/holidays',
                icon: 'fas fa-chart-line',
                permission: 'holidaysShowModule',
                children: []
            },
            {
                name: translate('menu_currencies', 'ვალუტები'),
                url: '/references/currencies',
                icon: 'fas fa-chart-line',
                permission: 'currenciesShowModule',
                children: []
            },
            {
                name: translate('menu_contract_types', 'კონტრაქტის ტიპები'),
                url: '/references/contract-types',
                icon: 'fas fa-chart-line',
                permission: 'contractTypesShowModule',
                children: []
            },
            {
                name: translate('menu_structural_units', 'სტრუქტურული ერთეულები'),
                url: '/references/structural-units',
                icon: 'fas fa-chart-line',
                permission: 'structuralUnitsShowModule',
                children: []
            },
            {
                name: translate('menu_purchases', 'შესყიდვები'),
                url: '/references/purchases',
                icon: 'fas fa-chart-line',
                permission: 'cascade',
                children: [
                    {
                        name: translate('menu_purchase_subjects', 'შესყიდვის საგნები'),
                        url: '/references/purchases/purchase-subjects',
                        icon: 'fas fa-chart-line',
                        permission: 'inventoryConfigurationsShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_purchase_specification', 'მახასიათებლები'),
                        url: '/references/purchases/purchase-specification',
                        icon: 'fas fa-chart-line',
                        permission: 'inventorySpecificationsShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_purchase_items', 'საგნები'),
                        url: '/references/purchases/purchase-items',
                        icon: 'fas fa-chart-line',
                        permission: 'inventoryItemsShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_categories', 'კატეგორიები'),
                        url: '/references/purchases/categories',
                        icon: 'fas fa-chart-line',
                        permission: 'inventoryCategoriesShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_subject_accounting', 'საბუღალტრო ანგარიშები'),
                        url: '/references/purchases/subject-accounting',
                        icon: 'fas fa-chart-line',
                        permission: 'inventoryCategoriesShowModule',
                        children: []
                    }
                ]
            },
            {
                name: translate('menu_budget_articles', 'საბიუჯეტო მუხლები'),
                url: '/references/budget-articles',
                icon: 'fas fa-chart-line',
                permission: 'budgetArticlesShowModule',
                children: []
            },
            {
                name: translate('menu_addresses', 'მისამართები'),
                url: '/references/addresses',
                icon: 'fas fa-chart-line',
                permission: 'cascade',
                children: [
                    {
                        name: translate('menu_countries', 'ქვეყნები'),
                        url: '/references/addresses/countries',
                        icon: 'fas fa-chart-line',
                        permission: 'countriesShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_regions', 'რეგიონები'),
                        url: '/references/addresses/regions',
                        icon: 'fas fa-chart-line',
                        permission: 'regionsShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_districts', 'რაიონები'),
                        url: '/references/addresses/districts',
                        icon: 'fas fa-chart-line',
                        permission: 'districtsShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_locations', 'ადგილმდებარეობები'),
                        url: '/references/addresses/locations',
                        icon: 'fas fa-chart-line',
                        permission: 'locationsShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_administrative_buildings', 'საუნივერსიტეტო კორპუსები'),
                        url: '/references/addresses/administrative-buildings',
                        icon: 'fas fa-chart-line',
                        permission: 'administrativeBuildingsShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_rooms', 'ოთახები'),
                        url: '/references/addresses/rooms',
                        icon: 'fas fa-chart-line',
                        permission: 'roomsShowModule',
                        children: []
                    }
                ]
            },
            {
                name: translate('menu_funding', 'დაფინანსებები'),
                url: '/references/funding',
                icon: 'fas fa-chart-line',
                permission: 'cascade',
                children: [
                    {
                        name: translate('menu_funding_sources', 'დაფინანსების წყაროები'),
                        url: '/references/funding/funding-sources',
                        icon: 'fas fa-chart-line',
                        permission: 'fundingSourcesShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_budget_sources', 'ბიუჯეტის წყაროები'),
                        url: '/references/funding/budget-sources',
                        icon: 'fas fa-chart-line',
                        permission: 'budgetSourcesShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_projects', 'პროექტები'),
                        url: '/references/funding/projects',
                        icon: 'fas fa-chart-line',
                        permission: 'projectsShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_donors', 'დონორები'),
                        url: '/references/funding/donors',
                        icon: 'fas fa-chart-line',
                        permission: 'donorsShowModule',
                        children: []
                    }
                ]
            },
            {
                name: translate('menu_human_resources', 'ადამიანური რესურსები'),
                url: '/references/human-resources',
                icon: 'fas fa-chart-line',
                permission: 'cascade',
                children: [
                    {
                        name: translate('menu_position_sub_types', 'თანამდებობის ქვეტიპები'),
                        url: '/references/human-resources/position-sub-types',
                        icon: 'fas fa-chart-line',
                        permission: 'positionSubTypeShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_position_types', 'თანამდებობის ტიპები'),
                        url: '/references/human-resources/position-types',
                        icon: 'fas fa-chart-line',
                        permission: 'positionTypesShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_position_type_configs', 'თანამდებობის ტიპების კონფიგურაცია'),
                        url: '/references/human-resources/position-type-configs',
                        icon: 'fas fa-chart-line',
                        permission: 'positionTypesConfigShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_work_types', 'შესასრულებელი სამუშაოს სახეები'),
                        url: '/references/human-resources/work-types',
                        icon: 'fas fa-chart-line',
                        permission: 'workTypesShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_employment_contract_types', 'შრომითი ხელშეკრულების ტიპები'),
                        url: '/references/human-resources/contract-types',
                        icon: 'fas fa-chart-line',
                        permission: 'HRContractsShowModule',
                        children: []
                    },
                    {
                        name: translate('menu_work_directions', 'სამუშაო მიმართულება'),
                        url: '/references/human-resources/work-directions',
                        icon: 'fas fa-chart-line',
                        permission: 'workDirectionsShowModule',
                        children: []
                    }
                ]
            },
            {
                name: translate('menu_warranty_terms', 'საგარანტიო ვადები'),
                url: '/references/warranty-terms',
                icon: 'fas fa-chart-line',
                permission: 'warrantyTermsShowModule',
                children: []
            },
            {
                name: translate('menu_cpv_codes', 'CPV კოდები'),
                url: '/references/cpv-codes',
                icon: 'fas fa-chart-line',
                permission: 'cpvCodesShowModule',
                children: []
            },
            {
                name: translate('menu_dimension_units', 'განზომილების ერთეულები'),
                url: '/references/dimension-units',
                icon: 'fas fa-chart-line',
                permission: 'dimensionUnitsShowModule',
                children: []
            },
            {
                name: translate('menu_procurement_types', 'შესყიდვის ტიპები'),
                url: '/references/procurement-types',
                icon: 'fas fa-chart-line',
                permission: 'purchaseProcurementTypeRead',
                children: []
            },
            {
                name: translate('menu_legal_basics', 'იურიდიული საფუძვლები'),
                url: '/references/legal-basics',
                icon: 'fas fa-chart-line',
                permission: 'legalBasicsShowModule',
                children: []
            },
            {
                name: translate('warranty_of_object_purchase', 'შესყიდვის ობიექტის გარანტია'),
                url: '/references/guarantee-articles',
                icon: 'fas fa-chart-line',
                permission: 'guaranteeArticlesShowModule',
                children: []
            },
            {
                name: translate('menu_warehouses', 'საწყობები'),
                url: '/references/warehouses',
                icon: 'fas fa-chart-line',
                permission: 'warehouseShowModule',
                children: []
            }
        ]
    },
    {
        name: translate('menu_administer', 'ადმინისტრირება'),
        url: '/administer',
        icon: 'fas fa-user',
        permission: 'cascade',
        children: [
            {
                name: translate('menu_users', 'მომხმარებლები'),
                url: '/administer/users',
                icon: 'fas fa-chart-line',
                permission: 'usersShowModule',
                children: []
            },
            {
                name: translate('menu_roles', 'როლები'),
                url: '/administer/roles',
                icon: 'fas fa-chart-line',
                permission: 'rolesRead',
                children: []
            },
            {
                name: translate('menu_news', 'სიახლეები'),
                url: '/administer/news',
                icon: 'fas fa-chart-line',
                permission: 'usersShowModule',
                children: []
            },
            {
                name: translate('menu_files', 'ფაილები'),
                url: '/administer/files',
                icon: 'fas fa-chart-line',
                permission: 'mediaFilesShowModule',
                children: []
            },
            {
                name: translate('menu_sent_emails', 'მეილები'),
                url: '/administer/sent-emails',
                icon: 'fas fa-chart-line',
                permission: 'emailsShowModule',
                children: []
            },
            {
                name: translate('menu_auth_histories', 'ავტორიზაციების ისტორია'),
                url: '/administer/auth-histories',
                icon: 'fas fa-chart-line',
                permission: 'userAuthLogsShowModule',
                children: []
            }
        ]
    },
    {
        name: translate('menu_personal_documents', 'პირადი სივრცე'),
        url: '/personal-documents',
        icon: 'fas fa-user',
        permission: 'cascade',
        children: [
            {
                name: translate('menu_my_structure', 'ჩემი სტრუქტურა'),
                url: '/personal-documents/my-structure',
                icon: 'fas fa-chart-line',
                permission: 'structuralUnitsShowModule',
                children: []
            },
            {
                name: translate('menu_my_files', 'ჩემი ფაილები'),
                url: '/personal-documents/my-files',
                icon: 'fas fa-chart-line',
                permission: 'mediaFilesShowModule',
                children: []
            }
        ]
    }
]);
exports.rawMenuItems = rawMenuItems;
